//smartwatch

//lights

//power supply

//keyboard

//

const projects = [
  {
    id: 1,
    title: "Smartwatch",
    description: "Description",
    fullDescription: "Quack",
    technologies: ["C++", "PCB Design", "3D Design", "Embedded", "UI/UX design"],
    hasExternalLink: true,
    externalLink: "https://example.com",
    githubLink: "https://github.com/username/repo",
    model: "./watch.gltf"
  },
  {
    id: 2,
    title: "Power supply",
    description: "Description",
    fullDescription: "No description",
    technologies: ["Electronics", "3D Printing", "3D Design"],
    hasExternalLink: true,
    externalLink: "https://example.com",
    githubLink: "https://github.com/username/repo",
  },
  {
    id: 3,
    title: "Smart Lights",
    description: "Wifi/Bluetooth enabled leds",
    fullDescription: "No description",
    technologies: ["Alexa", "Embedded", "C++"],
    hasExternalLink: true,
    externalLink: "https://example.com",
    githubLink: "https://github.com/username/repo",
    model: "./light.gltf"
  },
  {
    id: 4,
    title: "Keyboard",
    description: "A custom mechanical keyboard",
    fullDescription: "No description",
    technologies: ["Alexa", "Embedded", "C++"],
    hasExternalLink: true,
    externalLink: "https://example.com",
    githubLink: "https://github.com/username/repo",
    model: "./keyboard.gltf",
    initialRotation: {x: 90, y: 20, z: 0},
  },
  {
    id: 5,
    enabled: true,
    title: "Lightshot",
    description: "A simple photo culling app",
    fullDescription: "A simple photo culling app",
    technologies: ["App","Flutter", "Dart"],
    hasExternalLink: false,
    githubLink: "https://github.com/angelogerminario/Lightshot",
    model: "./keyboard.gltf",
    isIcon: true,
    icon: "./lightshot2.png",
    initialRotation: {x: 0, y: 0, z: 0},
  },
];

export default projects;