import React, {
  useRef,
  useMemo,
  useCallback,
  useEffect,
  useState
} from "react";
import { Canvas, useFrame, extend, useThree } from "@react-three/fiber";
import { OrbitControls, shaderMaterial } from "@react-three/drei";
import * as THREE from "three";
import { motion, useScroll, useTransform } from "framer-motion"
import { UserSquare } from "lucide-react";

const vertices = 150;

// Define WaveShaderMaterial
const WaveShaderMaterial = shaderMaterial(
  {
    uTime: 0,
    uNoiseScale: 1.0,
    uNoiseSpeed: 1,
    uWaveAmplitude: 1,
    uColorLow: new THREE.Color(0.1, 0.1, 0.8),
    uColorMid: new THREE.Color(1.0, 0.0, 1.0),
    uColorHigh: new THREE.Color(1.0, 0.1, 0.1),
    uFogColor: new THREE.Color(0.0, 0.0, 0.0),
    uFogNear: 1,
    uFogFar: 10,
    uWireframe: true,
    uVertices: 100,
    uLineThickness: 2.5,
    uSquareColor: new THREE.Color(0.0, 0.0, 0.0),
  },
  // vertex shader
  `
    varying vec2 vUv;
    varying float vElevation;
    varying float vFogDepth;

    uniform float uTime;
    uniform float uNoiseScale;
    uniform float uNoiseSpeed;
    uniform float uWaveAmplitude;

    //	Classic Perlin 3D Noise 
    //	by Stefan Gustavson
    //
    vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
    vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
    vec3 fade(vec3 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}

    float cnoise(vec3 P){
      vec3 Pi0 = floor(P); // Integer part for indexing
      vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
      Pi0 = mod(Pi0, 289.0);
      Pi1 = mod(Pi1, 289.0);
      vec3 Pf0 = fract(P); // Fractional part for interpolation
      vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
      vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
      vec4 iy = vec4(Pi0.yy, Pi1.yy);
      vec4 iz0 = Pi0.zzzz;
      vec4 iz1 = Pi1.zzzz;

      vec4 ixy = permute(permute(ix) + iy);
      vec4 ixy0 = permute(ixy + iz0);
      vec4 ixy1 = permute(ixy + iz1);

      vec4 gx0 = ixy0 / 7.0;
      vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
      gx0 = fract(gx0);
      vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
      vec4 sz0 = step(gz0, vec4(0.0));
      gx0 -= sz0 * (step(0.0, gx0) - 0.5);
      gy0 -= sz0 * (step(0.0, gy0) - 0.5);

      vec4 gx1 = ixy1 / 7.0;
      vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
      gx1 = fract(gx1);
      vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
      vec4 sz1 = step(gz1, vec4(0.0));
      gx1 -= sz1 * (step(0.0, gx1) - 0.5);
      gy1 -= sz1 * (step(0.0, gy1) - 0.5);

      vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
      vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
      vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
      vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
      vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
      vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
      vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
      vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

      vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
      g000 *= norm0.x;
      g010 *= norm0.y;
      g100 *= norm0.z;
      g110 *= norm0.w;
      vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
      g001 *= norm1.x;
      g011 *= norm1.y;
      g101 *= norm1.z;
      g111 *= norm1.w;

      float n000 = dot(g000, Pf0);
      float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
      float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
      float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
      float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
      float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
      float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
      float n111 = dot(g111, Pf1);

      vec3 fade_xyz = fade(Pf0);
      vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
      vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
      float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
      return 2.2 * n_xyz;
    }

    void main() {
      vUv = uv;
      
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      
      float elevation = cnoise(vec3(modelPosition.xz * uNoiseScale, uTime * uNoiseSpeed)) * uWaveAmplitude;
      
      modelPosition.y += elevation;
      
      vElevation = elevation;

      vec4 viewPosition = viewMatrix * modelPosition;
      vec4 projectedPosition = projectionMatrix * viewPosition;
    
      gl_Position = projectedPosition;

      vFogDepth = -viewPosition.z;
    }
  `,
  // fragment shader
  `
  varying vec2 vUv;
  varying float vElevation;
  varying float vFogDepth;
  uniform vec3 uColorLow;
  uniform vec3 uColorMid;
  uniform vec3 uColorHigh;
  uniform float uWaveAmplitude;
  uniform vec3 uFogColor;
  uniform float uFogNear;
  uniform float uFogFar;
  uniform bool uWireframe;
  uniform float uVertices;
  uniform float uLineThickness;
  uniform vec3 uSquareColor;

  void main() {
      float normalizedElevation = (vElevation + uWaveAmplitude) / (2.0 * uWaveAmplitude);
      vec3 color;
      float midStart = 0.3;
      float midEnd = 0.7;

      if (normalizedElevation < 0.5) {
          if(normalizedElevation > midStart){
              color = mix(uColorLow, uColorMid, smoothstep(midStart, 0.45, normalizedElevation));
          } else {
              color = uColorLow;
          }
      } else {
          if(normalizedElevation < midEnd){
              color = mix(uColorMid, uColorHigh, smoothstep(0.45, midEnd, normalizedElevation));
          } else {
              color = uColorHigh;
          }
      }

      // Apply fog
      float fogFactor = smoothstep(uFogNear, uFogFar, vFogDepth);
      color = mix(color, uFogColor, fogFactor);
      vec3 finalSquareColor = mix(uSquareColor, uFogColor, fogFactor);

      if(uWireframe){
          vec2 coord = fract(vUv * uVertices);
          vec2 grid = abs(fract(coord - 0.5) - 0.5) / fwidth(coord);
          float line = min(grid.x, grid.y);
          
          // Adjust line thickness and invert the effect
          float wireframe = 1.0 - smoothstep(0.0, uLineThickness, line);
          
          // Mix between black (for the "spaces") and the terrain color (for the lines)
          gl_FragColor = vec4(mix(finalSquareColor, color, wireframe), 1.0);
      } else {
          gl_FragColor = vec4(color, 1.0);
      }
  }
  `
);

extend({ WaveShaderMaterial });

const WavyPlane = React.forwardRef(function WavyPlane(
  {
    noiseScale,
    noiseSpeed,
    waveAmplitude,
    colors,
    fogNear,
    fogFar,
    bottomOffset
  },
  ref
) {
  const shaderRef = useRef();
  useFrame(({ clock }) => {
    if (shaderRef.current) {
      shaderRef.current.uTime = clock.getElapsedTime();
    }
  });

  const shaderProps = useMemo(
    () => ({
      uNoiseScale: noiseScale,
      uNoiseSpeed: noiseSpeed,
      uWaveAmplitude: waveAmplitude,
      uColorLow: colors.low,
      uColorMid: colors.mid,
      uColorHigh: colors.high,
      uFogColor: colors.fog,
      uSquareColor: colors.square,
      uFogNear: fogNear,
      uFogFar: fogFar,
      uVertices: vertices,
    }),
    [noiseScale, noiseSpeed, waveAmplitude, colors, fogNear, fogFar]
  );

  return (
    <mesh ref={ref} rotation={[-Math.PI / 2, 0, 0]} position={[0, bottomOffset, 0]}>
      <planeGeometry args={[30, 30, vertices, vertices]} />
      <waveShaderMaterial ref={shaderRef} {...shaderProps} />
    </mesh>
  );
});

const Scene = React.memo(function Scene({
  noiseScale,
  noiseSpeed,
  waveAmplitude,
  colors,
  fogNear,
  fogFar,
}) {
  const { camera } = useThree();
  const [targetRotationY, setTargetRotationY] = useState(0);
  const currentRotationY = useRef(0);
  const planeRef = useRef();

  useEffect(() => {
    camera.fov = 75;
    camera.position.set(0, 2, 5);
    camera.updateProjectionMatrix();
  }, [camera]);

  const handleMouseMove = useCallback((event) => {
    const rotationSpeed = 0.0006;
    const newTargetRotationY =
      (event.clientX - window.innerWidth / 4) * rotationSpeed;
    setTargetRotationY(newTargetRotationY);
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove]);

  const { scrollYProgress } = useScroll();

  useFrame(() => {
    // Camera rotation
    currentRotationY.current +=
      (targetRotationY - currentRotationY.current) * 0.005;
    camera.rotation.y = currentRotationY.current;

    // Plane position update based on scroll
    if (planeRef.current) {
      const scrollValue = scrollYProgress.get();
      if (scrollValue <= 0.2) {
        // Map 0-0.2 to 0-(-20)
        planeRef.current.position.y = -(scrollValue * 5)-1.5; // 0.2 * 100 = 20
      } else {
        // Keep it at -20 for the rest of the scroll
        planeRef.current.position.y = -2.5;
      }
    }
  });
  
  return (
    <>
      <WavyPlane
        ref={planeRef}
        noiseScale={noiseScale}
        noiseSpeed={noiseSpeed}
        waveAmplitude={waveAmplitude}
        colors={colors}
        fogNear={fogNear}
        fogFar={fogFar}
        bottomOffset={-2} // Initial position
      />
      <OrbitControls
        enablePan={false}
        enableZoom={false}
        enableRotate={false}
        minPolarAngle={Math.PI / 2}
        maxPolarAngle={Math.PI / 2}
      />
    </>
  );
});

const WavyGradientBackground = React.memo(function WavyGradientBackground({
  noiseScale = 0.4,
  noiseSpeed = 0.2,
  waveAmplitude = 1,
  colorLow = [0.1, 0.1, 0.8],
  colorMid = [1, 0.0, 1],
  colorHigh = [1.0, 0.1, 0.1],
  fogColor = [0.0, 0.0, 0.0],
  squareColor = [0.0, 0.0, 0.0],
  fogNear = 1,
  fogFar = 8,
}) {
  const colors = useMemo(
    () => ({
      low: new THREE.Color(...colorLow),
      mid: new THREE.Color(...colorMid),
      high: new THREE.Color(...colorHigh),
      fog: new THREE.Color(...fogColor),
      square: new THREE.Color(...squareColor),
    }),
    [colorLow, colorMid, colorHigh, fogColor, squareColor]
  );

  return (
    <>
      <motion.div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 0,
          backgroundColor: "transparent"
        }}
      >
        <Canvas
          style={{
            zIndex: 2,
          }}>
          <React.Suspense fallback={null}>
            <Scene
              noiseScale={noiseScale}
              noiseSpeed={noiseSpeed}
              waveAmplitude={waveAmplitude}
              colors={colors}
              fogNear={fogNear}
              fogFar={fogFar}
            />
          </React.Suspense>
        </Canvas>
      </motion.div>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 0,
          pointerEvents: "auto",
          background: "transparent",
        }}
      />
    </>
  );
});
//last div is to draw some transparent shit in front or the orbital controls make in impossible to scroll on mobile

export default WavyGradientBackground;
