import React, { useState, useEffect, useRef } from "react";
import { motion, useReducedMotion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Github, Linkedin, Mail, ExternalLink } from "lucide-react";

const ContactCard = ({ title, icon: Icon, link, color, description }) => {
  return (
    <motion.div
      style={{
        perspective: "1000px",
        transformStyle: "preserve-3d",
      }}
      className="w-full max-w-md"
    >
      <motion.a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="block w-full"
        style={{
          backfaceVisibility: "hidden",
          WebkitBackfaceVisibility: "hidden",
          transformStyle: "preserve-3d",
        }}
        whileHover={{
          scale: 1.01,
          transition: {
            duration: 0.3,
            ease: "easeOut",
          },
        }}
        whileTap={{ scale: 0.99 }}
      >
        <div 
          style={{borderColor: color+"77"}} 
          className="group  border-[1px] overflow-hidden bg-black/20 backdrop-blur-md rounded-2xl shadow-xl transition-all duration-300 hover:shadow-2xl hover:bg-black/30"
        >
          <div className="relative flex h-full p-5">
            <div className="rounded-xl bg-white/10 h-fit p-2 backdrop-blur-sm ring-1 ring-white/20 transition-all duration-300 group-hover:bg-white/15 group-hover:ring-white/30">
              <Icon size={29} strokeWidth={1.5} className="text-white" style={{color: color}}/>
            </div>

            <div className="flex flex-col gap-2 ml-4 flex-1">
              <div className="flex items-center justify-between">
                <h3 className="text-xl sm:text-2xl font-bold text-white">
                  {title}
                </h3>
                <div className="rounded-lg bg-white/10 p-2">
                  <ExternalLink className="text-white/80"/>
                </div>
              </div>
              
              <p className="text-sm text-white/70 group-hover:text-white/90 transition-colors duration-300">
                {description}
              </p>
            </div>
          </div>
        </div>
      </motion.a>
    </motion.div>
  )
};

const ContactGrid = ({ contacts }) => {
  const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.15 },
    },
  };

  const cardVariants = {
    hidden: {
      rotateY: 40,
      opacity: 0,
      transformPerspective: 1000,
      transformStyle: "preserve-3d",
    },
    visible: {
      rotateY: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 70,
        damping: 20,
      },
    },
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: false, margin: "-100px" }}
      className="flex flex-wrap justify-center gap-4 sm:gap-6 w-full max-w-5xl px-4 sm:px-6"
    >
      {contacts.map((contact) => (
        <ContactCard {...contact} />
      ))}
    </motion.div>
  );
};

const ContactSection = () => {
  const { t } = useTranslation();
  const contacts = [
    {
      id: "github",
      title: "GitHub",
      icon: Github,
      link: "https://github.com/angeloger",
      color: "#A843D7",
      description: t("githubSubText"),
    },
    {
      id: "linkedin",
      title: "LinkedIn",
      icon: Linkedin,
      link: "https://linkedin.com/in/angelo-germinario",
      color: "#0A66C2",
      description: t("linkedinSubText"),
    },
    {
      id: "email",
      title: "Email",
      icon: Mail,
      link: "mailto:angelogerminario1@gmail.com",
      color: "#EA4335",
      description: t("mailSubText"),
    },
  ];

  return (
    <div className="relative min-h-[100dvh] w-full flex flex-col items-center justify-center">
      <div className="flex-1 w-full flex flex-col items-center justify-center">
        <motion.h2
          className={`relative p-10 z-20 text-[2.7rem] font-light text-center text-white`}
          style={{
            textShadow: `0 0 5px green, 0 0 10px green, 0 0 15px green, 0 0 20px green`,
            fontFamily: "Neon",
          }}
        >
          {t("contactsTitle")}
          <p className="text-xl" style={{textShadow: `0 0 10px white`}}>{t("contactsSubTitle")}</p>
        </motion.h2>
        <ContactGrid contacts={contacts} />
      </div>
    </div>
  );
};

export default ContactSection;
