import React, { useState } from 'react';

const FlippingAvatar = ({ avatarSrc }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div 
      className="w-[15rem] lg:w-[20rem] h-[15rem] lg:h-[20rem] cursor-pointer perspective-1000"
      onClick={handleClick}
    >
      <div className={`relative w-full h-full transition-transform duration-700 transform-style-preserve-3d ${isFlipped ? 'rotate-y-180' : ''}`}>
        {/* Front side - Avatar */}
        <div className="absolute w-full h-full backface-hidden">
          <img src={avatarSrc} className="w-full h-full object-contain" alt="Avatar" />
        </div>
        
        {/* Back side - Duck SVG */}
        <div className="absolute w-full h-full backface-hidden rotate-y-180">
          <img src="duck.png" className='p-10'></img>
        </div>
      </div>
    </div>
  );
};

// Add required Tailwind CSS classes
const style = document.createElement('style');
style.textContent = `
  .perspective-1000 { perspective: 1000px; }
  .backface-hidden { backface-visibility: hidden; }
  .transform-style-preserve-3d { transform-style: preserve-3d; }
  .rotate-y-180 { transform: rotateY(180deg); }
`;
document.head.appendChild(style);

export default FlippingAvatar;