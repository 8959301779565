import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import projects from "./projects-data.js";
import ProjectCard from "./project-card";
import ProjectModal from "./project-modal";
import "../../assets/fonts/neon/neonfont.css";

const ProfessionalNeonBanner = ({
  text = "More coming soon",
  baseColor = "#00FFCCFF",
}) => {
  const [flicker, setFlicker] = useState(false);

  useEffect(() => {
    const flickerInterval = setInterval(() => {
      if (Math.random() < 0.2) {
        setFlicker(true);
        setTimeout(() => setFlicker(false), 50 + Math.random() * 100);
      }
    }, 100);
    return () => clearInterval(flickerInterval);
  }, []);

  const getColor = (opacity) => {
    const r = parseInt(baseColor.slice(1, 3), 16);
    const g = parseInt(baseColor.slice(3, 5), 16);
    const b = parseInt(baseColor.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  return (
    <div className="flex justify-center items-center my-10">
      <div
        className={`relative text-2xl font-semibold py-3 px-6 rounded-md transition-all duration-100 ${
          flicker ? "opacity-60" : "opacity-100"
        }`}
        style={{
          color: "white",
          textShadow: `0 0 7px ${getColor(0.8)}, 0 0 10px ${getColor(0.8)}, 0 0 21px ${getColor(0.6)}`,
          backgroundColor: "transparent",
          border: `2px solid ${baseColor}`,
          boxShadow: `0 0 7px ${getColor(0.8)}, 0 0 10px ${getColor(0.4)}, 0 0 21px ${getColor(0.2)}, inset 0 0 7px ${getColor(0.8)}, inset 0 0 10px ${getColor(0.4)}, inset 0 0 21px ${getColor(0.2)}`,
          fontFamily: "'Roboto', sans-serif",
          letterSpacing: "0.05em",
        }}
      >
        {text}
      </div>
    </div>
  );
};

const ProjectsSection = ({ color, colorLow, colorMid, colorHigh, squareColor }) => {
  const { t } = useTranslation();
  const [selectedProject, setSelectedProject] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    const topObserver = new IntersectionObserver(
      ([entry]) => {
        // Check if the header is partially visible and at the top
        const headerRect = entry.boundingClientRect;
        const isPartiallyVisible = headerRect.top <= 0 && headerRect.bottom > 0;
        setIsSticky(isPartiallyVisible);
      },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        rootMargin: "-1px 0px 0px 0px"
      }
    );

    if (headerRef.current) {
      topObserver.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        topObserver.unobserve(headerRef.current);
      }
    };
  }, []);

  return (
    <div className="relative">
      <motion.h2
        ref={headerRef}
        className={`sticky my-[5rem] top-0 left-0 right-0 p-2 z-20 text-[2.7rem] font-light text-center text-white transition-all duration-300 ${
          isSticky ? `backdrop-blur-xl bg-black/10` : "bg-transparent border-b-0 border-[#00f0ff30]"
        }`}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          textShadow: `0 0 5px blue, 0 0 10px blue, 0 0 15px blue, 0 0 20px blue`,
          fontFamily: "Neon"
        }}
      >
        {t("projectsTitle")}
      </motion.h2>

      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-0 px-0">
          {projects.map((project, index) => (
            <ProjectCard
              key={project.id}
              project={project}
              index={index}
              model={project.model}
              totalProjects={projects.length}
              onClick={() => {
                console.log("ProjectCard clicked:", project.id);
                setSelectedProject(project);
              }}
            />
          ))}
        </div>
        <ProfessionalNeonBanner text={t("comingSoonText")} />
      </div>

      <AnimatePresence mode="wait">
        {selectedProject && (
          <ProjectModal
            key="modal"
            project={selectedProject}
            onClose={() => {
              console.log("Closing modal");
              setSelectedProject(null);
            }}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProjectsSection;